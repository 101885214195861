import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../assets/scss/topBox.scss';

import {ReactComponent as User} from '../assets/img/user.svg';


class TopBox extends Component {

    render() {
        return (
            <div className={'topBox'}>

                <div className={'languageSwitch'}>
                    <div className={'lang lang_de theme_background_hover' + (this.props.state.lang === 'de' ? ' theme_background' : '')} onClick={e => this.props.setLanguage('de')}>DE</div>
                    <div className={'lang lang_en theme_background_hover' + (this.props.state.lang === 'en' ? ' theme_background' : '')} onClick={e => this.props.setLanguage('en')}>EN</div>
                </div>

                <div className={'section theme_color theme_bordercolor'}>
                    {this.props.section}
                </div>

                <div className={'users'}>
                    <div className={'name'}>
                        {(!!this.props.state.username && this.props.state.username.length) ? this.props.state.username : 'Coachee'}
                    </div>
                    <User className={'status user' + (this.props.state.users.includes('1') ? ' active' : '')} title={this.props.state.users.includes('1') ? 'Coachee online' : 'Coachee offline'} />
                    <User className={'status admin' + (this.props.state.users.includes('2') ? ' active' : '')} title={this.props.state.users.includes('2') ? 'Coach online' : 'Coach offline'} />
                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        TopBox
    )
);
