import React, {Component} from 'react';
import {connect} from 'react-redux';

let current = 0;
let currentCoin = null;

class PercentageCell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: !!this.props.data ? this.props.data.value : 0
        };

        this.onDrop = this.onDrop.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onDrag(e) {
        currentCoin = e.target.getAttribute('question');
    }

    onDrop(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('question');
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add');

        if (cell === currentCoin && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            this.props.change(this.props.section, this.props.question, current);
        }
    }

    dragOver(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('question');
        let elm = e.target;

        if (cell === currentCoin && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            elm.classList.add('highlight', 'background_hover_add');

            if (!!elm.getAttribute('elm')) {
                current = parseInt(elm.getAttribute('elm'));
            }
        }
    }

    dragLeave(e) {
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add');
    }

    onClick(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('question');
        let elm = e.target;
        let current = elm.getAttribute('elm');

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');

        if (!!cell && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            this.props.change(this.props.section.replace(' ', '-'), this.props.question, current);
        }
    }

    render() {

        let coin;
        let percentage;

        if ( this.props.elm === this.state.current) {
            coin = React.createElement(
                'img',
                {
                    elm: this.props.elm,
                    key: 'coin_' + this.props.section + '_' + this.props.elm,
                    className: 'jeton',
                    draggable: true,
                    onDragStart: this.onDrag,
                    question: this.props.question,
                    src: '../assets/img/coins/' + this.props.data.coin,
                    disabled: this.props.state.results.timeMeaning === false
                }
            )
        }

        if (this.props.percentage !== null) {
            percentage = React.createElement(
                'svg',
                {
                    key: 'percentage_' + this.props.section + '_' + this.props.elm,
                    className: 'percentage',
                    width:'100%',
                    height:'100%',
                },
                React.createElement(
                    'text',
                    {
                        key: 'index_text',
                        className: 'index_text',
                        x:'50%',
                        y:'50%',
                        textAnchor: 'middle',
                        alignmentBaseline: 'central',
                        dominantBaseline: 'middle',
                        fill: '#fff',
                        fontSize: 10
                    },
                    this.props.percentage + '%'
                )
            );
        }

        return React.createElement(
            'td',
            {
                key: 'td_' + this.props.section + '_' + this.props.elm,
                elm: this.props.elm,
                className: 'pop' + (this.props.disabled ? '' : ' background_hover') + (this.state.current >= this.props.elm ? ' moreThan' : '') /*+ (this.state.current + this.props.rest < this.props.elm ? 'notAvailable' : '')*/,
                question: this.props.question,
                onDragOver: this.dragOver,
                onDragLeave: this.dragLeave,
                onDrop: this.onDrop,
                onClick: this.onClick,
                disabled: this.props.disabled
            },
            [
                percentage,
                coin
            ]
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                current: this.props.data.value
            });
        }
    }
}

export default connect(
    (state) => {
        return {
            state: state
        }
    },
    null
)(
    PercentageCell
);
