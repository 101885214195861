import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import marked from "./markdown";

import '../assets/scss/imprint.scss';
import TopBox from "./topBox.class";
import {getTranslationHeadline} from "./helper";


class Start extends Component {
    render() {
        if (Object.keys(this.props.state.imprint).length) {
            return (
                <div className={'imprintContainer'}>

                    <TopBox section={getTranslationHeadline(this.props.state.headlines, this.props.state.lang, 'Impressum')} setLanguage={this.props.setLanguage} />

                    <div className={'imprint'}>
                        <section dangerouslySetInnerHTML={{__html: (!!this.props.state.imprint[this.props.state.lang]['Impressum'] ? marked(this.props.state.imprint[this.props.state.lang]['Impressum']) : '')}} />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        Start
    )
);
