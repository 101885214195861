import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {sha256} from 'js-sha256';
import Clipboard from 'react-clipboard.js';
import queryString from 'query-string';


import {setData} from "../redux/actions";

import '../assets/scss/login.scss';
import axios from "axios";

import loading from '../assets/img/loading.gif';

import {ReactComponent as Copy} from '../assets/img/copy.svg';
import {ReactComponent as Check} from '../assets/img/check.svg';


class Login extends Component {
    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search)

        this.state = {
            current: 'login',
            loading: false,

            ok: false,
            error: false,

            login: {
                name: (!!params.login && !!params.user) ? params.user : '',
                password: (!!params.login && !!params.password) ? params.password : ''
            },
            admin: {
                password: '',
                name: '',
                adminpassword: ''
            },
            link: '',
        }

        this.onSuccess = this.onSuccess.bind(this);
    }


    setTab(tab) {
        this.setState({
            current: tab,
            ok: false,
            error: false
        });
    }


    change(section, e) {
        let target = e.target;

        let properties = {...this.state[section]};
        properties[target.name] = target.value;

        this.setState({
            [section]: properties
        });
    }

    async submitLogin(e) {
        if (!!e) {
            e.preventDefault();
        }

        this.setState({
            error: '',
            loading: true
        });

        await axios.post((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_URI'] : window.location.protocol + '//' + window.location.hostname + '/api.php') + '?controller=data&action=login', {
            timestamp: new Date().getTime()
        }, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                'User': this.state.login.name,
                'Password': sha256(this.state.login.password)
            }
        }).then((result) => {

            localStorage.setItem('user', JSON.stringify(result.data.token));
            localStorage.setItem('userType', JSON.stringify(result.data.user));

            this.props.setData({
                user: result.data.token,
                userType: result.data.user,
                username: result.data.name
            });

            this.setState({
                loading: false
            });
        }).catch(() => {
            this.setState({
                error: this.props.state.language[this.props.state.lang]['LoginIncorrect'],
                loading: false
            });
        });
    }

    submitPassword(e) {
        e.preventDefault();

        this.setState({
            error: '',
            loading: true
        });

        axios.post((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_URI'] : window.location.protocol + '//' + window.location.hostname + '/api.php') + '?controller=data&action=changePassword', {}, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                'User': this.state.admin.name,
                'Authorization': sha256(this.state.admin.adminpassword),
                'Password': sha256(this.state.admin.password)
            }
        }).then(() => {
            this.setState({
                ok: this.props.state.language[this.props.state.lang]['PasswordChanged'],
                loading: false,
                link: window.location.href + '?login=true&user=' + this.state.admin.name + '&password=' + this.state.admin.password
            });
        }).catch(() => {
            this.setState({
                error: this.props.state.language[this.props.state.lang]['LoginIncorrect'],
                loading: false
            });
        });
    }

    onSuccess() {
        this.setState({
            copied: true
        });
    }

    render() {
        if (Object.keys(this.props.state['language']).length) {
            return (
                <div className={'overlay'}>
                    <div className={'login'}>
                        <ul>
                            <li className={(this.state.current === 'login' ? 'current theme_color': '')} onClick={() => this.setTab('login')}>{this.props.state['language'][this.props.state.lang]['SignUp']}</li>
                            <li className={(this.state.current === 'admin' ? 'current theme_color': '')} onClick={() => this.setTab('admin')}>{this.props.state['language'][this.props.state.lang]['Administration']}</li>
                        </ul>

                        {this.state.current === 'login' &&
                        <form onSubmit={e => this.submitLogin(e)}>
                            <p>{this.props.state['language'][this.props.state.lang]['Welcome']}</p>

                            <div className={'box'}>
                                <input name={'name'} placeholder={this.props.state['language'][this.props.state.lang]['Firstname']} value={this.state.login.name} onChange={e => this.change('login', e)} maxLength={12} />
                            </div>

                            <div className={'box'}>
                                <input type={'password'} name={'password'} placeholder={this.props.state['language'][this.props.state.lang]['Password']} value={this.state.login.password} onChange={e => this.change('login', e)} />
                            </div>

                            {this.state.error &&
                            <div className={'error'}>
                                {this.state.error}
                            </div>
                            }

                            <div className={'bottomBox'}>
                                <div className={'space'} />

                                <button type={'submit'} className={'theme_background'}>
                                    {this.state.loading ?
                                        <img src={loading} alt={'Loading...'} />:
                                        this.props.state['language'][this.props.state.lang]['SignIn']
                                    }
                                </button>

                                <div className={'logo'} style={{backgroundImage: 'url(' + this.props.state.config['Logo'] + ')'}} />
                            </div>

                        </form>
                        }

                        {this.state.current === 'admin' &&
                        <form onSubmit={e => this.submitPassword(e)}>
                            <p>{this.props.state['language'][this.props.state.lang]['WelcomeCoach'].replace('*coach*', this.props.state['Auswertung'][this.props.state.lang]['Coach'])}</p>

                            <div className={'box'}>
                                <input type={'password'} name={'adminpassword'} placeholder={this.props.state['language'][this.props.state.lang]['Adminpassword']} value={this.state.admin.adminpassword} onChange={e => this.change('admin', e)} required={true} />
                            </div>

                            <div className={'box'}>
                                <input name={'name'} placeholder={this.props.state['language'][this.props.state.lang]['Coacheename']} value={this.state.admin.name} onChange={e => this.change('admin', e)} maxLength={12} />
                            </div>

                            <div className={'box'}>
                                <input name={'password'} placeholder={this.props.state['language'][this.props.state.lang]['NewPassword']} value={this.state.admin.password} onChange={e => this.change('admin', e)} required={true} />
                            </div>

                            <div className={'box copy' + (this.state.copied ? ' copied' : '')}>
                                {this.state.link
                                    ?
                                    <Clipboard data-clipboard-text={this.state.link} onSuccess={this.onSuccess}>
                                        <input name={'link'} placeholder={this.props.state['language'][this.props.state.lang]['YourLink']} value={this.state.link} readOnly={true} className={this.state.copied ? 'copied' : ''} />
                                        {this.state.copied
                                            ?
                                            <Check className={'check'} />
                                            :
                                            <Copy className={'copy'} />
                                        }
                                    </Clipboard>
                                    :
                                    <input name={'link'} placeholder={this.props.state['language'][this.props.state.lang]['YourLink']} value={this.state.link} disabled={true} />
                                }

                            </div>


                            {this.state.error &&
                            <div className={'error'}>
                                {this.state.error}
                            </div>
                            }

                            {this.state.ok &&
                            <div className={'ok'}>
                                {this.state.ok}
                            </div>
                            }

                            <div className={'bottomBox'}>
                                <div className={'space'} />

                                <button type={'submit'} className={'theme_background'}>
                                    {this.state.loading ?
                                        <img src={loading} alt={'Loading...'} /> :
                                        this.props.state['language'][this.props.state.lang]['Apply']
                                    }
                                </button>

                                <div className={'logo'} style={{backgroundImage: 'url(' + this.props.state.config['Logo'] + ')'}} />
                            </div>

                        </form>
                        }

                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }


    componentDidMount() {

        let params = queryString.parse(this.props.location.search)

        if (!!params.login && !!params.user && !!params.password) {
            setTimeout(() => {
                this.submitLogin();
            }, 200);
        }
    }
}


export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Login
    )
);
