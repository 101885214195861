import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/cell.scss';

let current = 0;
let currentCoin = null;

class Cell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: this.props.data.value
        };

        this.onDrop = this.onDrop.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.onClick = this.onClick.bind(this);
    }


    onDrag(e) {
        currentCoin = e.target.getAttribute('dataquestion');
    }

    onDrop(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add');

        if (cell === currentCoin && !elm.hasAttribute('disabled')) {
            this.props.change(this.props.section, this.props.question, current);
        }
    }

    dragOver(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;

        if (cell === currentCoin && !elm.hasAttribute('disabled')) {
            elm.classList.add('highlight', 'background_hover_add');

            if (!!elm.getAttribute('elm')) {
                current = parseInt(elm.getAttribute('elm'));
            }
        }
    }

    dragLeave(e) {
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add');
    }

    onClick(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;
        let current = elm.getAttribute('elm');

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');

        if (!!cell && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            this.props.change(this.props.section.replace(' ', '-'), this.props.question, current);
        }
    }

    render() {

        let div;

        if ( this.props.elm === this.state.current) {
            div = React.createElement(
                'img',
                {
                    elm: this.props.elm,
                    className: 'jeton',
                    draggable: true,
                    onDragStart: this.onDrag,
                    dataquestion: this.props.question,
                    src: '../assets/img/coins/' + this.props.data.coin
                }
            )
        }

        return React.createElement(
            'td',
            {
                elm: this.props.elm,
                className: 'cell' + (this.props.elm === 0 ? ' zero' : ' background_hover'),
                dataquestion: this.props.question,
                onDragOver: this.dragOver,
                onDragLeave: this.dragLeave,
                onDrop: this.onDrop,
                onClick: this.onClick,
                disabled: this.props.elm === 0 || this.props.disabled
            },
            div
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                current: this.props.data.value
            });
        }
    }
}

export default withRouter(Cell);
