import React, {Component} from 'react';
import {connect} from 'react-redux';
import PercentageCell from "./percentageCell.class";
import {withRouter} from "react-router-dom";


class Percentage extends Component {
    render() {
        let pop = [];
        let z = 0;

        let rest = 100 - this.props.totalPercantage;

        let disabled = false;
        if (this.props.state.results.timeMeaning === false) {
            disabled = true;
        }

        for(let i = 0; i < 23; i++) {
            if (i === 0 || i === 22) {
                let classname = '';

                if (i === 22) {
                    classname = (rest !== 100) ? ' grey' : '';
                }

                if (i === 0) {
                    classname = ' moreThan';
                }

                pop.push(
                    <td className={'pop disabled' + classname} key={'pop_' + this.props.section + '_' + i} />
                );
            } else {
                pop.push(
                    <PercentageCell key={'pop_' + this.props.section + '__' + i} elm={z} percentage={((z % 10 === 0) ? z : null)} section={this.props.section} data={this.props.data} question={this.props.question} change={this.props.change} rest={rest} disabled={disabled} />
                );

                z+=5;
            }
        }

        return pop;
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        Percentage
)
);
