import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setData} from "../redux/actions";
import axios from "axios";
import {loggedin, userType, logout} from "./helper";

import '../assets/scss/login.scss';


class Logout extends Component {

    logout(e) {
        if (e !== null) {
            e.preventDefault();
        }

        axios.post((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_URI'] : window.location.protocol + '//' + window.location.hostname + '/api.php') + '?controller=data&action=logout', {
            timestamp: new Date().getTime(),
            userType: userType()
        }, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: loggedin()
            }
        }).then(() => {
            logout(this.props);
            this.props.breaktimer();
        });
    }

    render() {
        if (this.props.state.logout) {
            if (this.props.state.userType === 2) {
                this.logout(null);
                return <></>
            } else {
                return (
                    <div className={'overlay'}>
                        <div className={'logout'}>
                            <p>
                                {this.props.state.language[this.props.state.lang]['LogoutText']}
                            </p>

                            <div className={'bottomBox'}>
                                <div className={'button theme_background'} onClick={e => this.props.setData({logout: false})}>{this.props.state.language[this.props.state.lang]['Cancel']}</div>
                                <div className={'button theme_background'} onClick={e => this.logout(e)}>{this.props.state.language[this.props.state.lang]['Logout']}</div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return <></>
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Logout
    )
);
