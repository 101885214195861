import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

import Quarter from "./quarter.class";

import '../assets/scss/field.scss';


class Field extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            visible: {
                1: true,
                2: false,
                3: false,
                4: false
            }
        }
    }


    render() {

        let i = 0;
        let fields = [];
        if (!!this.props.state.structure && this.props.state.structure.size > 0) {
            this.props.state.structure.forEach((v, k) => {

                let position = 'bottom';
                let style = {};

                if (i >= 2) {
                    position = 'top';
                }

                if (i === 0) {
                    style = {
                        top:0,
                        left:0
                    }
                } else if (i === 1) {
                    style = {
                        top:0,
                        right:0
                    }
                } else if (i === 2) {
                    style = {
                        bottom:0,
                        left:0
                    }
                } else {
                    style = {
                        bottom:0,
                        right:0
                    }
                }
                style.backgroundImage = 'url(' + this.props.state['backgrounds'][this.props.state.lang][k] + ')';

                i++;
                fields.push(<Quarter key={k} change={this.props.change} section={k} display={position} top={this.props.state['questions'][this.props.state.lang][k][0]['section']} style={style} />);
            })
        }

        return (
            <div className={'gameField'}>
                    <div className={'gameFieldBox'}>
                        {fields}

                        <img src={'assets/img/zentrum.png'} alt={'center'} className={'center ' + (this.props.state.zoomed ? 'small' : '')} />
                    </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        Field
    )
);
