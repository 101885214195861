import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

import Percentage from "./percentage.class";
import Cell from "./cell.class";

import {ReactComponent as Plus} from '../assets/img/plus.svg';
import {ReactComponent as Minus} from '../assets/img/line.svg';

import '../assets/scss/quarter.scss';
import {getTotalPercentage, getTranslationHeadline} from "./helper";


const CellFunc = (props) => {

    let cells = [];
    for(let i = -5; i <= 5; i++) {
        cells.push(<Cell {...props} elm={i} key={'cell' + i} />);
    }

    return cells;
};


class Quarter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rerender: false
        }
    }

    render() {

        let row = [];
        for(let i = 0; i < 6; i++) {
            row.push(
                React.createElement(
                    'tr',
                    {
                        key: 'row_' + i
                    },
                    <CellFunc change={this.props.change} section={this.props.section} question={i} data={this.props.state['questions'][this.props.state.lang][this.props.section][i]} />
                )
            );
        }

        let className = '';
        if (this.props.state.results.timeMeaning === false) {
            className = 'disabled';
        }

        return (
            <div className={'table g' + this.props.section.replace(' ', '') + ' ' + this.props.display + ' ' + (this.props.state.zoomed ? (this.props.location.pathname === '/' + this.props.section ? 'big' : 'small') : '')} style={this.props.style}>
                <Plus className={'plus'} />
                <Minus className={'minus'} />

                <table>
                    <tbody>
                        {this.props.display === 'bottom' &&
                            <>
                                {row}

                                <tr>
                                    <td colSpan={11} className={'timeMeaning ' + className}>
                                        <table cellPadding={0} cellSpacing={0} border={0}>
                                            <tbody>
                                                <tr className={'meaning'}>
                                                    <Percentage section={this.props.section} data={this.props.state['Meaning']['questions'][this.props.state.lang][this.props.section]} question={8} change={this.props.change} totalPercantage={getTotalPercentage(this.props, 'Meaning')} />
                                                </tr>
                                                <tr className={'time'}>
                                                    <Percentage section={this.props.section} data={this.props.state['Time']['questions'][this.props.state.lang][this.props.section]} question={7} change={this.props.change} totalPercantage={getTotalPercentage(this.props, 'Time')} />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr className={'description'}>
                                    <td colSpan={11}>
                                        <svg className={'percentage'} width={'100%'} x={'0'} y={0} viewBox={'0 0 500 100'} xmlns="http://www.w3.org/2000/svg">
                                            <text className={'index_text'} x={'50%'} y={'50%'} textAnchor={'middle'} alignmentBaseline={'central'} fill={'#fff'} fontSize={30}>
                                                {getTranslationHeadline(this.props.state.headlines, this.props.state.lang, this.props.top.replace(' ', '-'))}
                                            </text>
                                        </svg>
                                    </td>
                                </tr>
                            </>
                        }

                        {this.props.display === 'top' &&
                        <>
                            <tr className={'description'}>
                                <td colSpan={11}>
                                    <svg className={'percentage'} width={'100%'} x={'0'} y={0} viewBox={'0 0 500 50'} xmlns="http://www.w3.org/2000/svg">
                                        <text className={'index_text'} x={'50%'} y={'50%'} textAnchor={'middle'} alignmentBaseline={'central'} fill={'#fff'} fontSize={30}>
                                            {getTranslationHeadline(this.props.state.headlines, this.props.state.lang, this.props.top.replace(' ', '-'))}
                                        </text>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={11} className={'timeMeaning ' + className}>
                                    <table cellPadding={0} cellSpacing={0} border={0}>
                                        <tbody>
                                            <tr className={'meaning'}>
                                                <Percentage section={this.props.section} data={this.props.state['Meaning']['questions'][this.props.state.lang][this.props.section]} question={8} change={this.props.change} totalPercantage={getTotalPercentage(this.props, 'Meaning')} />
                                            </tr>
                                            <tr className={'time'}>
                                                <Percentage section={this.props.section} data={this.props.state['Time']['questions'][this.props.state.lang][this.props.section]} question={7} change={this.props.change} totalPercantage={getTotalPercentage(this.props, 'Time')} />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {row}
                        </>
                        }

                    </tbody>
                </table>
            </div>
        );
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        Quarter
    )
);
